'use client';
import Image from 'next/image';
import React from 'react';
import { motion } from 'motion/react';
import { Button } from '@/components/ui/button';
import { useWindowSize } from '@uidotdev/usehooks';
import { useTranslate } from '@tolgee/react';

const CoinsTab = () => {
    const { t } = useTranslate();
    const [expanded, setExpanded] = React.useState(false);
    const width = useWindowSize().width ?? 0;
    const isMobile = width < 1024;
    return (
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
            <div className="flex flex-col justify-center">
                <p>{t('landingPage.coinsTab.text1')}</p>
                {isMobile ? (
                    <>
                        <motion.div
                            initial={{
                                opacity: 0,
                                height: 0,
                                overflow: 'hidden',
                                paddingTop: 0
                            }}
                            animate={{
                                opacity: expanded ? 1 : 0,
                                height: expanded ? 'auto' : 0,
                                overflow: 'hidden',
                                paddingTop: expanded ? 15 : 0
                            }}>
                            <p>{t('landingPage.coinsTab.text2')}</p>
                        </motion.div>
                        <Button
                            variant="ghost"
                            size="sm"
                            className="bg-transparent hover:bg-transparent"
                            onClick={() => setExpanded(!expanded)}>
                            {expanded
                                ? t('landingPage.intro.showless')
                                : t('landingPage.intro.showmore')}
                        </Button>
                    </>
                ) : (
                    <>
                        <p className="mt-4">{t('landingPage.coinsTab.text2')}</p>
                    </>
                )}
            </div>
            <div className="relative aspect-video w-full">
                <Image
                    src="/landing/3.jpg"
                    alt="demo"
                    fill
                    className="absolute overflow-hidden rounded-md object-cover"
                />
            </div>
        </div>
    );
};

export default CoinsTab;
