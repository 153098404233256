import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/.pnpm/next@15.1.6_@babel+core@7.26.7_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/AkLogo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landingPage/ConnectAndGet.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landingPage/DownloadApp.section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landingPage/ExploreSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landingPage/Hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landingPage/HowItWorksSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landingPage/LandingHeader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landingPage/LenisProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landingPage/StatsSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/landingPage/TopGirls.section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/MaxWidthWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Separator"] */ "/app/src/components/ui/separator.tsx");
