'use client';
import { useTranslate } from '@tolgee/react';
import MaxWidthWrapper from '../MaxWidthWrapper';
import { useQueries } from '@tanstack/react-query';
import {
    countChatMessages,
    countCreatorContent,
    countCreators,
    countUsers
} from '@/actions/landing.actions';

const StatsSection = () => {
    const { t } = useTranslate();

    const [creators, content, users, messages] = useQueries({
        queries: [
            {
                queryKey: ['akGirls.count'],
                queryFn: () => countCreators()
            },
            {
                queryKey: ['photos.videos'],
                queryFn: () => countCreatorContent()
            },
            {
                queryKey: ['users.count'],
                queryFn: () => countUsers()
            },
            {
                queryKey: ['chat.count'],
                queryFn: () => countChatMessages()
            }
        ]
    });

    return (
        <div className="relative overflow-hidden py-20">
            <div className="absolute inset-0 -z-10 h-[100%]">
                <div className="blurBg absolute inset-0 z-10 bg-black/60" />
                <video
                    autoPlay
                    muted
                    loop
                    playsInline
                    className="h-full w-full object-cover">
                    <source
                        src="/landing/demovid.mp4"
                        type="video/mp4"
                    />
                </video>
            </div>
            <MaxWidthWrapper className="space-y-10 text-center">
                <h2>{t('landingPage.stats.title')}</h2>
                <div className="grid grid-cols-2 place-items-center justify-items-center gap-5 lg:grid-cols-4">
                    <div className="mt-5 flex flex-col items-center">
                        <span className="text-5xl font-bold">{creators.data}</span>
                        <span>AK GIRLS</span>
                    </div>
                    <div className="mt-5 flex flex-col items-center">
                        <span className="text-5xl font-bold">{content.data}</span>
                        <span>{t('landingPage.stats.photosAndVideos')}</span>
                    </div>
                    <div className="mt-5 flex flex-col items-center">
                        <span className="text-5xl font-bold">{users.data}</span>
                        <span>{t('landingPage.stats.users')}</span>
                    </div>
                    <div className="mt-5 flex flex-col items-center">
                        <span className="text-5xl font-bold">{messages.data}</span>
                        <span>{t('landingPage.stats.chatMessages')}</span>
                    </div>
                </div>
            </MaxWidthWrapper>
        </div>
    );
};

export default StatsSection;
